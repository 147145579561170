import { Component, DestroyRef, HostBinding, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AsyncPipe, NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { map, startWith, take } from 'rxjs/operators';
import { QueryRef } from 'apollo-angular';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Event, User } from 'src/app/services/hobbyts.service';
import { PlayerCardComponent } from '../player-card/player-card.component';
import { EventCardComponent } from '../event-card/event-card.component';
import { getMatDialogConfig, getSnackBarConfig } from 'src/app/utils/material.utils';
import { EventsService } from '../events.service';
import { IAddRemovePlayerEventInput } from 'src/app/shared/model/addremoveplayer.interface';
import { calculateChatName, generateEventLink, generateInitials, generatePositions } from 'src/app/utils/profile.utils';
import { DialogNames, LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { LoginModalComponent } from 'src/app/modals/login-modal';
import { RefreshService } from 'src/app/entry/refresh.service';
import { FeatureFlagDirective } from 'src/app/shared/feature-flag.directive';
import { ButtonStateAnimationDirective } from 'src/app/shared/button-animation.directive';
import { CustomSnackBar } from 'src/app/shared/custom-snackbar/custom-snackbar.component';
import { ConfirmationModalComponent } from 'src/app/modals/confirmation-modal';
import { StorageService } from 'src/app/services/storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hb-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    EventCardComponent,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatButtonModule,
    PlayerCardComponent,
    NgClass,
    AsyncPipe,
    FeatureFlagDirective,
    MatTooltipModule,
    ButtonStateAnimationDirective,
    MatSlideToggleModule
  ]
})
export class EventDetailsComponent implements OnInit {
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;
  public eventDetail!: Event;
  public showJoin = true;
  public players: User[] = [];
  public loggedInUserId!: string | null;
  public eventOwnerId!: string | null;
  public isOwner: boolean;
  public isInvited: boolean;
  public allUsers: User[] = [];
  public searchUser = new FormControl();
  public filteredOptions!: Observable<User[]>;
  public mapLink = '';
  public isFull: boolean;
  private eventId!: string;

  private queryRef: QueryRef<any, { eventId: string }>;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private eventsService: EventsService,
    private lazyDialog: LazyDialogService,
    public dialog: MatDialog,
    private refreshService: RefreshService,
    private clipboard: Clipboard,
    private storageService: StorageService,
    private destroyRef: DestroyRef,
  ) { }

  public ngOnInit(): void {
    this.eventId = this.activeRoute.snapshot.paramMap.get('id') ?? '';
    this.loggedInUserId = this.storageService.getValue('store-userId');

    this.getEventsDetail();
    this.filteredOptions = this.searchUser.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : `${value?.firstName} ${value?.lastName}`)),
      map((name) => (name ? this.filter(name) : this.allUsers.slice()))
    );
  }

  public navigateToMainPage(): void {
    this.navigateToOtherPage('home');
  }

  public navigateToProfilePage(): void {
    this.navigateToOtherPage('profile', this.eventOwnerId ?? '');
  }

  public navigateToEditEventPage(): void {
    this.navigateToOtherPage('edit-event', this.eventId);
  }

  public navigateToInvitePage(): void {
    this.navigateToOtherPage('invite', this.eventId);
  }

  public joinEvent(): void {
    this.performActionIfAuthenticated(() => {
      this.eventsService.joinEvent(this.eventId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        this.showJoin = false;
        this.openSnackBar($localize`You successfully joined this event`, $localize`:@@close:Close`);
      });
    });
  }

  public leaveEvent(): void {
    this.performActionIfAuthenticated(() => {
      this.eventsService.leaveEvent(this.eventId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: any) => {
        this.showJoin = true;
        this.openSnackBar(res.data.leaveEvent.message, $localize`:@@close:Close`);
      });
    });
  }

  public acceptEvent(): void {
    this.performActionIfAuthenticated(() => {
      this.eventsService.acceptEventInvitation(this.eventId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: any) => {
        this.showJoin = false;
        this.isInvited = false;
        this.openSnackBar(res.data.acceptEventInvitation, $localize`:@@close:Close`);
      });
    });
  }

  public declineEvent(): void {
    this.performActionIfAuthenticated(() => {
      this.eventsService.declineEventInvitation(this.eventId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: any) => {
        this.showJoin = true;
        this.isInvited = false;
        this.openSnackBar(res.data.declineEventInvitation, $localize`:@@close:Close`);
      });
    });
  }

  public displayFn(user: User): string {
    return user?.firstName ? `${user.firstName} ${user.lastName ?? ''}` : '';
  }

  public addNewOrCreatedUserToEvent(): void {
    const userValue = this.searchUser.value;

    if (!userValue?.id) {
      const addPlayerEventInput = this.generateAddRemoveUserInput('', true, userValue);
      this.eventsService.addPlayerToEventDetail(addPlayerEventInput).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => { },
          error: (error) => {
            this.openSnackBar(error, $localize`:@@close:Close`)
          }
        });
      this.openSnackBar($localize`Custom player added`, $localize`:@@close:Close`);
    } else if (userValue?.id && this.checkIfPlayerExist()) {
      const addPlayerEventInput = this.generateAddRemoveUserInput(userValue.id, true, '');
      this.eventsService.addPlayerToEventDetail(addPlayerEventInput).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => { },
          error: (error) => {
            this.openSnackBar(error.message, $localize`:@@close:Close`)
          }
        });
    } else {
      this.openSnackBar($localize`Please select user first!`, $localize`:@@close:Close`);
    }
    this.searchUser.setValue('');
  }

  public async onPlayerDelete(player: User): Promise<void> {
    const confirmationMsg = $localize`Do you want to delete this player?`;
    const confirmButtonText = $localize`Delete`;
    const cancelButtonText = $localize`No`;
    const config = getMatDialogConfig(this.dialog, ConfirmationModalComponent, {
      confirmationMsg,
      confirmButtonText,
      cancelButtonText
    });
    await this.lazyDialog.openLazyLoadedDialog<ConfirmationModalComponent>(DialogNames.CONFIRM, config).then((ref) => {
      ref.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          const addPlayerEventInput = this.generateAddRemoveUserInput(player.playerId ?? '', false, '');
          this.eventsService.removePlayerToEventDetail(addPlayerEventInput)
            .pipe(
              take(1),
              takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
              next: () => {
                this.openSnackBar($localize`Player is deleted from event`, $localize`:@@close:Close`);
              },
              error: () => {
                this.openSnackBar($localize`The player could not be removed from the list. Try again later or contact admin`, $localize`:@@close:Close`);
              }
            });
        }
      });
    });
  }

  public toggleEventStatus(isFull: boolean): void {
    this.eventsService.toggleStatusEvent(this.eventDetail, isFull).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: () => {
        const { messageId, telegramMessageLink, telegramChannelLink } = calculateChatName(
          this.eventDetail.messageId ? this.eventDetail.messageId[0] : null
        );

        const snackBarText = $localize`Your changes have been saved ${messageId
          ? $localize`, you can check it in telegram channel <a href="${telegramChannelLink}" target="_blank" class="cyan-event-link">${telegramChannelLink}</a> or
        <a href="${telegramMessageLink}" target="_blank" class="cyan-event-link">${telegramMessageLink}</a>`
          : ''
          }`;

        const snackBarConfig = getSnackBarConfig();
        snackBarConfig.data = { snackBarText };
        this.snackBar.openFromComponent(CustomSnackBar, snackBarConfig);
      },
      error: () => {
        this.openSnackBar($localize`Error in toggle status!`, $localize`:@@close:Close`);
      }
    });
  }

  public copyLink(): void {
    this.clipboard.copy(generateEventLink(this.storageService, this.eventId));
    this.openSnackBar($localize`Link copied`, $localize`:@@close:Close`);
  }

  private getPlayerList(): void {
    const updatedPlayers = this.players.map((player: any) => {
      if (player.playerId === this.loggedInUserId) {
        this.showJoin = false;
      }

      const updatedPlayer = {
        ...player,
        positions: generatePositions(player),
        initial: generateInitials(player.firstName, player.lastName)
      };
      return updatedPlayer;
    });

    this.players = updatedPlayers;
  }

  private generateAddRemoveUserInput(playerId: string, isAdd: boolean, userName: string): IAddRemovePlayerEventInput {
    return {
      eventId: this.eventId,
      playerId,
      isAdd,
      userName
    };
  }

  private checkIfPlayerExist(): boolean {
    return !this.players.some((p) => p.playerId === this.searchUser.value.id);
  }

  private filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.allUsers.filter(
      (option: User) => option.firstName && option.firstName.toLowerCase().includes(filterValue)
    );
  }

  private openSnackBar(message: string, buttonTxt: string): void {
    this.snackBar.open(message, buttonTxt, getSnackBarConfig());
  }

  private navigateToOtherPage(route: string, id?: string): void {
    if (id) {
      void this.router.navigate([route, id]);
    } else {
      void this.router.navigate([route]);
    }
  }

  private subscribeToGetPlayers(): void {
    this.queryRef = this.eventsService.getPlayerList(this.eventId);
    this.queryRef.valueChanges.subscribe((res: any) => {
      this.players = res.data.getPlayerList;
      this.getPlayerList();
    });
  }

  private initEventPlayerData(eventDetail: Event): void {
    if (!this.refreshService.isAuthenticated$.getValue() && eventDetail.isPrivate) {
      this.navigateToOtherPage('');
    }

    this.eventDetail = eventDetail;
    this.eventOwnerId = eventDetail.ownerId;
    this.isOwner = !!eventDetail.isOwner;
    this.isInvited = !!eventDetail.isInvited;
    this.isFull = !!eventDetail.isFull;
    this.allUsers = eventDetail.userNetwork ?? [];
    this.mapLink = `https://www.google.com/maps/place/${eventDetail.lat ?? 0},${eventDetail.long ?? 0}`;
  }

  private getEventsDetail(): void {
    if (this.refreshService.isAuthenticated$.getValue()) {
      this.eventsService.eventDetailsAuthorized(this.eventId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: any) => {
        this.initEventPlayerData(res.getEventDetailAuthorized);
      });
    } else {
      this.eventsService.eventDetailsUnauthorized(this.eventId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res: any) => {
        this.initEventPlayerData(res.getEventDetailUnauthorized);
      });
    }
    this.subscribeToGetPlayers();
    this.eventsService.subscribeToMore(this.queryRef, this.eventId);
  }

  private async openLoginModal(): Promise<void> {
    const config = getMatDialogConfig(this.dialog, LoginModalComponent, `event-details/${this.eventId}`);
    await this.lazyDialog.openLazyLoadedDialog<LoginModalComponent>(DialogNames.LOGIN, config).then((ref) => {
      ref.afterClosed().subscribe(() => {
        this.getEventsDetail();
      });
    });
  }

  // Helper method to handle common authentication-related logic
  private performActionIfAuthenticated(action: () => void): void {
    if (this.refreshService.isAuthenticated$.getValue()) {
      action();
    } else {
      this.openLoginModal();
    }
  }
}
