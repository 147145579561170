<header class="top-bar flex-row justify-center" aria-label="header" i18n-aria-label>
  <mat-icon class="arrow-back cursor-pointer" (click)="navigateToMainPage()">arrow_back</mat-icon>
  <h2 class="page-label" i18n>Event Details</h2>
  <mat-icon
    class="copy-icon cursor-pointer"
    hbButtonStateAnimation
    [originalColor]="'var(--color-gold)'"
    [clickedColor]="'var(--color-blue-latest)'"
    (click)="copyLink()"
    *hbFeatureFlag="true"
    i18n-matTooltip
    matTooltip="Copy link"
    [matTooltipPosition]="'below'"
    #tooltip="matTooltip"
    >content_copy</mat-icon
  >
</header>

@if (eventDetail) {
  <div class="event-detail-container">
    <hb-event-card [event]="eventDetail" [isEventDetail]="'true'">
      <div class="flex-row align-center" *hbFeatureFlag="false">
        <mat-icon [svgIcon]="eventDetail.isIndoor ? 'home' : 'tree'"></mat-icon>
        @if (eventDetail.teamSize) {
          <p class="team-size-txt">{{ eventDetail.teamSize }}v{{ eventDetail.teamSize }}</p>
        }
      </div>
      <div class="flex-row space-between align-center" *hbFeatureFlag="true">
        @if (eventDetail.ownerName) {
          <p class="owner-text" (click)="navigateToProfilePage()">
            <a class="user-tag">&#64;{{ eventDetail.ownerName }}</a>
          </p>
        }
        <a class="location-row cursor-pointer flex-row align-center" href="{{ mapLink }}" target="_blank">
          <mat-icon svgIcon="location"></mat-icon>
          <p class="location-text">{{ eventDetail.fieldData?.title }}</p>
        </a>
      </div>
    </hb-event-card>
  </div>
}

<div class="players-container" *hbFeatureFlag="true">
  <div class="players-label">
    <span i18n>Players {{ players ? '(' + players.length + ')' : '(0)' }}</span>
  </div>
  @if (isOwner) {
    <div class="search flex-row space-evenly align-center gap-16">
      <mat-form-field appearance="outline">
        <mat-label class="mat-label" i18n>Type name of custom player</mat-label>
        <input
          type="text"
          matInput
          [formControl]="searchUser"
          [matAutocomplete]="auto"
          aria-label="search"
          i18n-aria-label
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          @for (option of filteredOptions | async; track option.id) {
            <mat-option [value]="option"> {{ option?.firstName }} {{ option?.lastName }} </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <button
        mat-raised-button
        class="add-button fill-button flex-1"
        (click)="addNewOrCreatedUserToEvent()"
        aria-label="Click here to add user to event"
        i18n-aria-label
      >
        <span class="va-middle" i18n>Add</span>
      </button>
    </div>
  }
  @if (players) {
    @for (player of players; track player.id; let index = $index) {
      <hb-player-card
        [player]="player"
        (onPlayerDelete)="onPlayerDelete($event)"
        [isOwner]="isOwner"
        [index]="index"
      ></hb-player-card>
    }
  }
</div>

@if (!isOwner) {
  <footer class="action-bar" aria-label="user-nav" i18n-aria-label *hbFeatureFlag="true">
    @if (isInvited) {
      <div class="h-100 flex-row justify-center align-center gap-16">
        <button
          class="flex-auto fill-button"
          mat-raised-button
          aria-label="Click here to join the event"
          (click)="acceptEvent()"
          i18n-aria-label
        >
          <mat-icon class="va-middle">add</mat-icon>
          <span class="va-middle" i18n>Accept</span>
        </button>
        <button
          class="flex-auto outline-button"
          mat-raised-button
          aria-label="Click here to leave the event"
          (click)="declineEvent()"
          i18n-aria-label
        >
          <mat-icon class="va-middle">remove</mat-icon>
          <span class="va-middle" i18n>Decline</span>
        </button>
      </div>
    } @else {
      @if (showJoin) {
        <button
          class="flex-auto fill-button"
          mat-raised-button
          aria-label="Click here to join the event"
          (click)="joinEvent()"
          i18n-aria-label
        >
          <mat-icon class="va-middle">add</mat-icon>
          <span class="va-middle" i18n>Join</span>
        </button>
      } @else {
        <button
          class="flex-auto outline-button"
          mat-raised-button
          aria-label="Click here to leave the event"
          (click)="leaveEvent()"
          i18n-aria-label
        >
          <mat-icon class="va-middle">remove</mat-icon>
          <span class="va-middle" i18n>Leave</span>
        </button>
      }
    }
  </footer>
} @else {
  @if (loggedInUserId && isOwner) {
    <footer class="action-bar mb-10" aria-label="owner-nav" i18n-aria-label *hbFeatureFlag="false">
      <mat-slide-toggle
        color="primary"
        labelPosition="before"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="isFull"
        (change)="toggleEventStatus(isFull)"
      >
        @if (isFull) {
          <span class="va-middle" i18n>Event is full</span>
        } @else {
          <span class="va-middle" i18n>Event is open to join</span>
        }
      </mat-slide-toggle>
    </footer>
  }
  <footer class="action-bar" aria-label="owner-nav" i18n-aria-label *hbFeatureFlag="true">
    <div class="h-100 flex-row justify-center align-center gap-16">
      <button
        class="leave flex-auto outline-button"
        mat-raised-button
        (click)="navigateToEditEventPage()"
        aria-label="Click here to edit the list"
        i18n-aria-label
      >
        <mat-icon class="va-middle">edit</mat-icon>
        <span class="va-middle" i18n>Edit List</span>
      </button>
      <button
        class="join flex-auto fill-button"
        mat-raised-button
        (click)="navigateToInvitePage()"
        aria-label="Click here to invite other"
        i18n-aria-label
      >
        <mat-icon class="va-middle">add</mat-icon>
        <span class="va-middle" i18n>Invite</span>
      </button>
    </div>
  </footer>
}
